.m-signature-pad--body {
  min-width: 200px;
  height: 200px;
  text-align: center;
}
.m-signature-pad--body canvas {
  width: 100%;
  height: 100%;
}
@media (min-width: 320px) and (max-width: 767px) {
  .w-650 {
    width: 100% !important;
  }
}
.w-650 {
  width: 650px;
}