/* Tabs panel */
.tabbable-panel {
  border:1px solid #eee;
  padding: 0px;
    text-align:center;
	border-radius:3px;
	padding-bottom:0px !important;
	margin-top:20px;
}




#tab_default_1
{
	padding:25px;
}
#tab_default_2
{
		padding:30px;
}
/* Default mode */
.tabbable-line > .nav-tabs {
  border: none;
  margin: 0px;
  text-align:center;
  
}
.tabbable-line > .nav-tabs > li {
  margin-right: 2px;
  width:49%;
}
.tabbable-line > .nav-tabs > li svg {
	width:15px;
	height:15px;
	color:#ACACAC;
	fill:#ACACAC;
}
.tabbable-line > .nav-tabs > li > a {
  border: 0;
  margin-right: 0;
  color: #ACACAC;
  text-align:center !important;
    font-size:20px;
}
.tabbable-line > .nav-tabs > li > a > i {
  color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open, .tabbable-line > .nav-tabs > li:hover {
  border-bottom: 3px solid #02477C;
}
.tabbable-line > .nav-tabs > li.open > a, .tabbable-line > .nav-tabs > li:hover > a {
  border: 0;
  background: none !important;
  color: #333333;
    font-size:20px;
}
.tabbable-line > .nav-tabs > li.open > a > i, .tabbable-line > .nav-tabs > li:hover > a > i {
  color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open .dropdown-menu, .tabbable-line > .nav-tabs > li:hover .dropdown-menu {
  margin-top: 0px;
}
.tabbable-line > .nav-tabs > li.active {
  border-bottom: 3px solid #02477C;
  position: relative;
    font-size:20px;
}
.tabbable-line > .nav-tabs > li.active > a {
  border: 0;
  color: #02477C;
  font-size:20px;
}
.tabbable-line > .nav-tabs > li.active > a svg {

  color: #02477C;
  fill:#02477C;
}
.tabbable-line > .nav-tabs > li.active > a > i {
  color: #404040;
}
.tabbable-line > .tab-content {
  margin-top: -3px;
  background-color: #fff;
  border: 0;
  border-top: 1px solid #eee;
  padding: 15px 0;
  padding-bottom:0px !important;
}
.portlet .tabbable-line > .tab-content {
  padding-bottom: 0;
  
}

/* Below tabs mode */

.tabbable-line.tabs-below > .nav-tabs > li {
  border-top: 4px solid transparent;
}
.tabbable-line.tabs-below > .nav-tabs > li > a {
  margin-top: 0;
}
.tabbable-line.tabs-below > .nav-tabs > li:hover {
  border-bottom: 0;
  border-top: 3px solid #fbcdcf;
}
.tabbable-line.tabs-below > .nav-tabs > li.active {
  margin-bottom: -2px;
  border-bottom: 0;
  border-top: 3px solid #f3565d;
}
.tabbable-line.tabs-below > .tab-content {
  margin-top: -10px;
  border-top: 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;

}
.panel-heading
{
	padding-top:20px;



}
.panel-title > a
{
	display:block;
}

@media  (min-width: 1200px) and (max-width: 2560px){
.contact_bg {
    height: 704px !important;
}

}

@media  (min-width: 993px) and (max-width: 1199px){
#tab_default_1 {
    padding: 10px !important;
}
#tab_default_2 {
    padding:10px !important;
}
.tabbable-line > .nav-tabs > li a {
    font-size: 14px !important;
    padding: 5px !important;
}
.tabbable-line > .nav-tabs > li:hover a {
    font-size: 14px !important;
}
.tabbable-line > .nav-tabs > li.active {
   
    margin-top: -3px;
}
}


@media  (min-width: 768px) and (max-width: 992px){
#tab_default_1 {
    padding: 10px !important;
}
#tab_default_2 {
    padding:10px !important;
}
.tabbable-line > .nav-tabs > li a {
    font-size: 12px !important;
    padding: 5px !important;
}
.tabbable-line > .nav-tabs > li:hover a {
    font-size: 12px !important;
}
.tabbable-line > .nav-tabs > li.active {
   
    margin-top: -3px;
}
}
@media  (min-width: 320px) and (max-width: 767px){
	
.res_tab_box
{
	border:solid 1px #E6E6E6;
	padding:10px;
	min-height: 250px;
line-height: 50px;
padding-top: 48px;
}
	.res_tab_box p
{
	text-align:center !important;
}
.res_tab_box img
{
	width:100%;
}	
.tabbable-panel {
    border: 1px solid #fff;
	padding:0px;
}	
.tabbable-line > .nav-tabs > li.active {
    border-bottom: 2px solid #02477C;
}
.tabbable-line > .nav-tabs > li {
    border-bottom: solid 2px #E6E6E6;
}
.tabbable-line > .nav-tabs > li.open, .tabbable-line > .nav-tabs > li:hover {
  border-bottom: 2px solid #02477C;
}
.tabbable-line > .nav-tabs > li > a
{
	font-size:17px;
}
}

@media  (max-width: 767px){
.need_help_container
{
	display:none;
}

.login_bg
{
	background:none !important;
}

.adres_map_label
{
	display:block;
}
}