.region-select {
  background-color: transparent;
  border: solid 1px #eceeee;
  border-radius: 0px;
  padding: 9px 12px;
  padding-left: 50px;
  width: 100%;
  text-align: center;
  color: #929496;
}

.region-select.register {
  width: 80% !important;
  padding-left: 75px !important;
  text-align: left !important;
}
