body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


#sw-msg-01{
  color: #fff !important;
  background-color: #43a047 !important;
  width: 350px !important;
  min-height: 50px !important;
  padding: 3px !important;
  cursor: default;

}

#sw-msg-01 .Toastify__toast-body .sw-refresh-btn {
    background: transparent;
    border: 0;
    float: right;
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;
}
#sw-msg-01 .Toastify__toast-body .sw-refresh-btn:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
.sw-refresh-btn:focus {
  box-shadow: none;
}
#sw-msg-01 .Toastify__toast-body {
  width: 100%;
}
#sw-msg-01 .Toastify__close-button {
  display: none;
}
.period_ending{
  padding-right: 0;
  width: 22% !important;
}
.timecard_cldr2{
  padding-left: 0;
}
.period_ending_popup3{
  padding-right: 0;
  width: 30% !important;
}
.time_card_popup3{
  padding-left: 0;
}